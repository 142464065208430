.search-filter-container {
  min-width: 300px;
  max-width: 300px;
  /* padding-right: 2rem; */
}

.filter-field-label {
  margin-bottom: 8px;
  font-family: "Public Sans", sans-serif;
}

.search-provider-card-container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding: 0 2rem 2rem 2rem;
  gap: 2rem;
}

.open-filter-drawer-button {
  position: fixed;
  top: 16px;
  right: 20px;
  z-index: 9999;
  visibility: hidden;
}

button.back-to-top-button span.anticon-arrow-up {
  font-size: 20px !important;
}

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.provider-search-card {
  /* height: 350px; */
  width: 600px;
}

.fertility-service-card {
  /* width: 300px; */
}

.fertility-service-card-img {
  max-width: 340px !important;
}

.fertility-service-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  padding: 0 2rem;
}

.match-percentage-text {
  color: white;
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
  font-size: 1.75rem;
  font-family: "Figtree";
  font-weight: 700;
  text-align: center;
  width: 100%;
}

@media screen and (width < 750px) {
  .provider-search-card {
    box-shadow: 2px 2px 8px 3px lightgray;
    width: 100%;
    min-width: 300px;
  }

  .match-percentage-text {
    font-size: 1.25rem;
    top: 0.25rem;
    right: 0;
  }
}

@media screen and ((width >= 750px) and (width <= 1100px)) {
  .fertility-service-card-container {
    gap: 16px;
  }

  .search-provider-card-container {
    /* gap: 0; */
    padding: 0 1rem 2rem 1rem !important;
  }

  .match-percentage-text {
    font-size: 1.25rem;
    top: 0.3rem;
    right: 0;
  }

  .provider-search-card {
    box-shadow: 2px 2px 8px 3px lightgray;
    width: 500px;
  }
}

@media screen and (max-width: 1350px) {
  .fertility-service-card {
    width: 280px;
  }

  .fertility-service-card-img {
    max-width: 240px !important;
  }

  .fertility-service-card-container {
    gap: 24px;
  }
}

@media screen and (max-width: 1100px) {
  .search-filter-container {
    display: none;
  }

  .open-filter-drawer-button {
    visibility: visible;
  }
}

@media screen and (min-width: 1101px) {
  .open-filter-drawer-button {
    display: none;
  }
}

@media screen and ((width >= 1651px) and (width <= 1850px)) {
  .provider-search-card {
    width: 600px;
  }
}

@media screen and ((width >= 1300px) and (width <= 1650px)) {
  .search-provider-card-container {
    /* gap: 0; */
    /* padding: 0 1rem 2rem 1rem !important; */
  }

  .match-percentage-text {
    font-size: 1.5rem;
    top: 0.3rem;
    right: -0.5rem;
  }

  .provider-search-card {
    width: 500px
  }
}