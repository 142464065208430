@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

.ff-flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ff-flex-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ff-space-around {
  justify-content: space-around;
}

.ff-flex-wrap {
  flex-wrap: wrap;
}

.ff-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.ff-flex-nowrap {
  flex-wrap: nowrap;
}

.ff-title-font {
  font-family: "Figtree", serif;
}

.ff-body-font {
  font-family: "Public Sans", sans-serif;
  color: rgb(128, 128, 128);
}






.App {
  text-align: center;
}

.ant-spin-fullscreen {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 9999 !important;
}

.app-header {
  padding-inline: 48px;
  margin-bottom: 2rem;
  height: 7em;
}

.app-header-logo {
  width: 260px !important;
}

.app-body {
  max-width: 1600px;
  padding-inline: 48px;
}

.app-header-buttons {
  display: flex;
  margin-top: 8px;
}

.fert-button {

}
.fert-button:disabled,
.fert-button[disabled] {
  border: none !important;
}

.home-title {
  text-align: left;
  font-weight: 700;
  font-size: 36px;
  color: rgb(0 0 0 / 80%);
  margin-bottom: 1em;
  font-family: "Figtree", serif;
}

.search-smarter-button-mobile {
  text-align: center;
  line-height: normal;
  display: none;
}

.search-smarter-button-normal {
  text-align: center;
  line-height: normal;
  margin-top: 12px;
}

.hidden-on-mobile {
  display: inherit;
}

.home-page-follow-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
  margin-top: 48px;
}

@media screen and (max-width: 800px) {
  .hidden-on-mobile {
    display: none !important;
  }

  .app-header {
    padding-left: 0px;
    padding-right: 12px;
    /* margin-bottom: 4rem; */
    height: 6em;
    margin-bottom: 1rem;
  }

  .app-header-logo {
    width: 200px !important;
  }

  /* .app-body {
    padding-inline: 16px;
    min-width: 0;
  } */

  .home-title {
    font-size: 24px;
  }

  .search-smarter-button-mobile {
    display: block;
  }

  .search-smarter-button-normal {
    display: none;
  }

  .home-page-follow-buttons {
    gap: 24px !important;
  }
}

@media screen and (min-width: 800px) {
  .app-body {
    margin: 0 auto;
  }
}






div, p, span {
  text-align: left;
}

button, input {
  user-select: none;
}

.radio-button-light:hover {
  background-color: #ebf9f5 !important;
}

.radio-button-primary:hover {
  background-color: #35B48A !important;
}

.fert-avatar-card {
  width: 345px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #CCCCCC !important;
  box-shadow: 0px 4px 8px #d9d9d9;
}

.ant-card-meta-title {
  margin-bottom: 0 !important;
}

.ant-card-meta-avatar {
  display: flex;
  align-items: center;
}

.ant-card-meta {
  margin-bottom: 16px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  max-width: 100%;
  display: block;
}

.about-our-story-paragraphs div {
  margin-bottom: 24px;
}
