.provider-page-content {
  width: 100%;
  /* padding: 0 1rem 2rem 1rem; */
  height: 320px;
  /* background-color: #5c5c5c; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.other-offices-header {
  font-size: 1.75rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
  font-family: "Figtree", serif;
  text-align: center;
}

.other-offices-expand-text {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.success-rate-left-pane {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 500px;
}

.success-rate-hidden-item {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  visibility: hidden;
}

.financial-icon-div {
  max-width: 10rem;
}

.financial-icon-div-container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 24px; */
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1400px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form-fields-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.invalid-input {
  border-color: red !important;
}

.minor-text-centered {
  text-align: center;
  font-family: "Figtree", serif;
}

.bar-chart-container {
  /* width: 500px; */
}

.provider-search-card-other-office {
  width: 500px;
}

.provider-contact-form-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 0 4rem;
}

@media screen and (min-width: 1400px) {
  .bar-chart-container {
    /* width: 600px; */
  }
}

@media screen and (max-width: 1200px) {
  .success-rate-left-pane {
    max-width: 400px !important;
  }

  .success-rate-hidden-item {
    display: none !important;
  }

  .form-fields-container {
    padding: 0;
  }

  .bar-chart-container {
    max-width: 370px;
  }
}

@media screen and (max-width: 800px) {
  .provider-search-card-other-office {
    width: 100%;
  }

  .provider-contact-form-text-container {
    padding: 0;
  }

  .other-offices-header {
    font-size: 1.25rem;
  }

  .other-offices-expand-text {
    font-size: 0.8rem;
    font-weight: 200;
  }
}