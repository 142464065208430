.bookmark-match {
  position: absolute;
  top: -0.1rem;
  right: 1rem;
}

.prem-prov-banner {
  position: absolute;
  bottom: -11px;
  left: -13px;
}

.search-card-provider-name {
  font-family: "Figtree", serif;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
}

.search-card-provider-details {
  position: absolute;
  top: 60%;
  width: 100% !important;
  text-align: center;
  padding: 0 1rem;
}

.search-card-provider-location {
  font-family: "Public Sans", sans-serif;
  text-align: center;
  color: rgba(0,0,0,0.4);
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

.search-card-more-btn {
  width: 8rem;
}

@media screen and (width <= 500px) {
  .search-card-provider-details {
    top: 55% !important;
  }
}

@media screen and (width <= 1150px) {
  .bookmark-match {
    top: -1px;
    right: 0;
  }

  .search-card-provider-name {
    font-size: 1rem;
    /* width: 100px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .search-card-provider-details {
    top: 60%;
    /* width: 300px !important; */
  }

  .search-card-provider-location {
    font-size: 0.85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .prem-prov-banner {
    /* position: absolute; */
    top: -2px;
    /* left: -13px; */
  }
}