.app-footer {
  text-align: center;
  color: #fff;
  background-color: #eef4f6;
  padding: 0;
}

.app-footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.app-footer-img-and-text-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.app-footer-logo-1 {
  margin-left: -48px;
}

.app-footer-logo-2 {
  margin-left: -40px;
}



@media screen and (min-width: 1000px) {
  .app-footer-content {
    max-width: 1000px;
    margin: 0 auto;
  }
}

@media screen and (width <= 500px) {
  .app-footer-img-and-text-container {
    gap: 0 !important;
  }

  .app-footer-content {
    flex-wrap: wrap;
    gap: 24px;
  }

  .app-footer {
    padding: 0 24px 24px 24px;
  }
}

@media screen and ((width <= 1050px) and (width > 500px)) {
  .app-footer {
    padding: 0 24px 24px 24px;
  }

  .app-footer-content {
    flex-wrap: wrap;
  }

  .app-footer-socials {
    margin-top: 0;
  }
}